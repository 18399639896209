var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"fullscreen"},[(_vm.formbuilderDetails && !_vm.isExpiredFormbuilder)?_c('div',{staticClass:"shadow-sm p-3 bg-body-tertiary rounded top-bar-wrapper"},[(_vm.formbuilderDetails.customization.show_title_bar)?_c('el-row',{staticClass:"top-bar"},[_c('el-col',{staticClass:"title ml-1",attrs:{"span":20}},[_c('span',{style:(_vm.getIsMobile
              ? 'font-size: 15px;font-weight: 500;color: #030503'
              : 'font-size: 1.5rem;font-weight: 500;color: #030503')},[_vm._v(_vm._s(_vm.formbuilderDetails.name))])]),_c('el-col',{staticClass:"image-container mr-1",attrs:{"span":4}},[(
            _vm.checkisViewFormbuilder &&
            _vm.userSubmissions &&
            _vm.userSubmissions.length > 0 &&
            this.$route.name !== 'ApplicationFormbuilderExecuteStep'
          )?_c('span',{staticClass:"submissions"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.gotoAllSubmissions}},[_vm._v("Go to all my submissions")])],1):_vm._e(),(_vm.formbuilderDetails.logo)?_c('img',{staticStyle:{"max-height":"40px"},attrs:{"src":_vm.formbuilderDetails.logo,"alt":"Form builder Logo"}}):(_vm.companyLogo)?_c('img',{staticStyle:{"max-height":"40px"},attrs:{"src":_vm.companyLogo,"alt":"Nimble logo"}}):(!_vm.logoTwoLoading)?_c('img',{staticClass:"img-fluid",staticStyle:{"max-height":"30px"},attrs:{"src":require("@/assets/kodefast-Logo-Black.png"),"alt":"Company logo"}}):_vm._e()])],1):_vm._e(),(_vm.formbuilderDetails.steps && _vm.formbuilderDetails.steps.length !== 1)?_c('div',{staticClass:"progress-bar"},[(
          _vm.formbuilderDetails &&
          _vm.formbuilderDetails.steps &&
          _vm.formbuilderDetails.steps.length > 4
        )?_c('div',{staticClass:"mt-1"},[_c('el-scrollbar',{attrs:{"wrap-style":"max-width: 1300px;"}},[_c('el-steps',{staticClass:"formbuilder-flow",attrs:{"active":parseInt(_vm.step) + 1,"space":200,"finish-status":"success","align-center":"","size":"mini"}},_vm._l((_vm.formbuilderDetails.steps),function(st,i){return _c('el-step',{key:'step_' + i,style:(_vm.stepStyle),attrs:{"title":_vm.getStepTitle(st)},nativeOn:{"click":function($event){return _vm.gotoStep(i)}}})}),1)],1)],1):_c('div',[_c('el-steps',{staticClass:"formbuilder-flow",attrs:{"active":parseInt(_vm.step) + 1,"space":400,"finish-status":"success","align-center":"","size":"mini"}},_vm._l((_vm.formbuilderDetails.steps),function(st,i){return _c('el-step',{key:'step_' + i,style:(_vm.stepStyle),attrs:{"title":_vm.getStepTitle(st)},nativeOn:{"click":function($event){return _vm.gotoStep(i)}}})}),1)],1)]):_vm._e()],1):_vm._e(),(_vm.formbuilderDetails && !_vm.isExpiredFormbuilder)?_c('div',{staticClass:"centered-box"},[(_vm.showData)?_c('div',{staticClass:"main-step-holder"},[(
          _vm.showData &&
          _vm.getCurrentStep &&
          _vm.getCurrentStep.type == 'TEMPLATE' &&
          _vm.getCurrentStep.template &&
          _vm.getCurrentStep.template.free_style == true
        )?_c('templatePreview',{attrs:{"currentStepIndex":_vm.step,"templateData":_vm.getCurrentStep.template,"isExecute":_vm.isEdit,"entityDataId":null,"currentStep":_vm.getCurrentStep,"formbuilderDetails":_vm.formbuilderDetails,"currentFormbuilderData":_vm.currentFormbuilderData,"currentFormbuilderDataObj":_vm.currentFormbuilderDataObj,"companyDetails":_vm.companyDetails},on:{"goto-next":_vm.moveToNextTemplate,"fetchFormbuilderData":_vm.fetchFormbuilderData}}):(
          _vm.showData &&
          _vm.getCurrentStep &&
          _vm.getCurrentStep.type == 'TEMPLATE' &&
          _vm.getCurrentStep.template &&
          _vm.getCurrentStep.template.free_style == false
        )?_c('templatePreviewFormStyle',{attrs:{"currentStepIndex":_vm.step,"templateData":_vm.getCurrentStep.template,"isExecute":_vm.isEdit,"entityDataId":null,"currentStep":_vm.getCurrentStep,"formbuilderDetails":_vm.formbuilderDetails,"currentFormbuilderData":_vm.currentFormbuilderData,"currentFormbuilderDataObj":_vm.currentFormbuilderDataObj,"companyDetails":_vm.companyDetails},on:{"goto-next":_vm.moveToNextTemplate,"fetchFormbuilderData":_vm.fetchFormbuilderData}}):(_vm.showData && _vm.getCurrentStep.type == 'ENTITY')?_c('div',[_c('entity-template-view',{attrs:{"currentEntity":_vm.getCurrentStep.entity,"isEdit":_vm.isEdit,"layout":'PROFILE',"isApplicationUserSide":false,"formbuilderDetails":_vm.formbuilderDetails,"currentFormbuilderData":_vm.currentFormbuilderData,"currentStepIndex":_vm.step,"currentStepData":_vm.getCurrentStep,"companyDetails":_vm.companyDetails},on:{"goto-next":_vm.moveToNextTemplate,"fetchFormbuilderData":_vm.fetchFormbuilderData}})],1):_vm._e()],1):_vm._e()]):(_vm.isExpiredFormbuilder)?_c('div',[_c('el-card',{style:({ backgroundColor: '#f0f8ff' })},[_c('div',{staticClass:"expiration-card"},[_c('el-col',{attrs:{"span":2}},[_c('i',{staticClass:"el-icon-timer timer-icon",style:(_vm.getIsMobile ? 'font-size: 40px;' : 'font-size: 55px;')})]),_c('el-col',{attrs:{"span":21,"offset":1}},[_c('el-row',[_c('p',{staticClass:"expiration-text"},[_vm._v(" "+_vm._s(_vm.formbuilderDetails.name)+" Form has expired on "+_vm._s(_vm._f("globalDateTimeFormat")(_vm.currentFormbuilderData.expires_at))+" ")])]),_c('el-row',[_c('div',{staticClass:"additional-msg"},[_c('ul',[_c('li',[_vm._v(" Sorry for the inconvenience. Please contact the form owner at: ")]),(
                    _vm.currentFormbuilderData &&
                    _vm.currentFormbuilderData.company_owner_email
                  )?_c('li',[_c('p',{staticStyle:{"color":"#007bff"}},[_vm._v(" "+_vm._s(_vm.currentFormbuilderData.company_owner_email)+" ")])]):_vm._e()])])])],1)],1)])],1):(!_vm.loading)?_c('div',{staticClass:"centered-box"},[_c('p',[_vm._v("Something went wrong")])]):_vm._e(),(
      _vm.getFormBuilder &&
      _vm.getFormBuilder.customization &&
      _vm.getFormBuilder.customization.show_initial_confirmation
    )?_c('el-drawer',{staticClass:"drawer-modal",attrs:{"title":_vm.formbuilderDetails && _vm.formbuilderDetails.name
        ? _vm.formbuilderDetails.name
        : '',"with-header":false,"visible":_vm.description_shown,"direction":"ttb","before-close":_vm.checkContinueClicked},on:{"update:visible":function($event){_vm.description_shown=$event}}},[_c('div',{staticClass:"esigns_logo"},[(_vm.companyLogo)?_c('img',{staticStyle:{"max-height":"30px","margin-top":"15px","margin-left":"25px"},attrs:{"src":_vm.companyLogo,"alt":"Nimble logo"}}):_c('img',{staticStyle:{"width":"130px","margin-top":"15px","margin-left":"25px"},attrs:{"src":require("@/assets/img/logo-white.svg")}})]),_c('div',{staticClass:"drawer-body"},[(_vm.formbuilderDetails)?_c('p',{staticClass:"fw-500",staticStyle:{"color":"#303133","font-weight":"400","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.formbuilderDetails.name)+" ")]):_vm._e(),_c('el-scrollbar',{attrs:{"wrap-style":"max-height: 100px;"}},[_c('p',{staticStyle:{"font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"20px","color":"#303133"}},[(_vm.formbuilderDetails && _vm.formbuilderDetails.description)?_c('span',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.formbuilderDetails.description)}}):_c('span',[_vm._v("Hello, Please click on Continue")])])]),_c('el-divider',{staticClass:"mt-0"}),_c('el-button',{staticStyle:{"border-radius":"2px"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.continueFilling}},[_vm._v("Continue")])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }